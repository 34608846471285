.btnDailyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btnDailyMailing {
  margin: 1rem;
}

.btnDailyMailing:hover {
  color: red;
}

button.btnDailyMailing {
  border: none;
  background-color: transparent;
}
