.settingsContainer {
  display: flex;
  justify-content: center;
}

.settingsItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px;
}

.title {
  margin-bottom: 50px;
}

.custom-select {
  width: 250px;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.delete-shop-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-left: 1px solid grey;
}

.shop-list {
  width: 80%;
  margin: 10px auto;
  overflow: auto;
  padding: 0;
  scrollbar-width: thin;
}

.shop-list li {
  list-style-type: none;
  padding: 5px 10px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 5px;
}

.card-li {
  margin-bottom: 5px;
}

.form-label {
  margin-top: 0.5rem;
}

::-webkit-scrollbar {
  height: 12px;
  width: 6px;
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}

@media screen and (max-width: 1100px) {
  .settingsContainer {
    flex-wrap: wrap;
  }
}
