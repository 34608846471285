/* Affichage des boutons */
.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnGenerationEtiquette {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  width: 150px;
  height: 50px;
  background-color: black;
  color: white;
  border-color: white;
  box-shadow: 0px 2px 5px grey;
  font-size: 18px;
  border-radius: 15px;
  cursor: pointer;
}

.btnGenerationEtiquette:hover {
  background-color: white;
  color: black;
  border-color: black;
}

/* Affichage du visuel de l'étiquette */
.EtiquetteSelected {
  margin-top: 2rem;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Affichage de l'étiquette en version imprimable */
@media print {
  @page {
    size: A4 landscape;
  }
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .no-print {
    display: none;
  }
}