.modifGeneral {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding-top: 3rem;
  height: 40vh;
}

.modifMail {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 25px 25px 25px;
}

.modifPassword {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 25px 25px 25px;
}

.settingsTitle {
  text-align: center;
}

.settingsPassword {
  display: contents;
}

@media screen and (max-width: 1100px) {
  .modifGeneral {
    flex-wrap: wrap;
  }
}
