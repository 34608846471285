/* Barre de recherche de références d'étiquette */

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchInputs {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-tagsinput {
  width: 450px;
  height: 50px;
  border-radius: 15px;
  font-family: sans-serif;
  font-size: 18px;
  background-color: black !important;
  color: white;
      padding-left: 10px !important;
  box-shadow: 0px 2px 5px grey;
  border: solid 1px white;
  margin-top: -1rem !important;
}

.react-tagsinput {
	padding-left: 0;
	margin-top: 0;
}

.react-tagsinput-tag {
    background-color: #fff !important;
    border: 1px solid #000 !important;
    color: #000 !important;
}

.react-tagsinput-input {
    font-size: inherit !important;
    width: auto !important;
}

.react-tagsinput input::placeholder {
  color: white;
}

.searchIcon svg {
  font-size: 50px;
  margin-top: -1rem;
}

#clearBtn {
  cursor: pointer;
}

.dataResult {
  width: 470px;
  max-height: 150px;
  margin-right: 30px;
  margin-left: 2px;
  background-color: black;
  border-radius: 9px;
  font-size: 18px;
  box-shadow: 0px 2px 5px grey;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult:empty {
  display: none;
}

.dataResult::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 15px;
}

.dataResult::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 0 15px 15px 0;
}

.dataItem {
  width: 100%;
  color: white;
  font-family: sans-serif;
  font-size: 18px;
  background-color: black;
}

option.dataItem:hover {
  background-color: #1e90ff;
  cursor: pointer;
}

option.dataItem {
  padding: 0 0 0 10px;
}
