/* conteneur englobant l'ensemble des informations */
table{
    border-collapse:collapse;
}
tr>td {
  padding: 5px;
}
table,
tr,
td,
div {
    page-break-inside: avoid;
}
.EtiquetteDemi{
	width: 5.7cm;
	height: 4cm;
}
.no-margin-print{margin-top: 20px;}
.no-margin-top-left{
	margin-top: 20px;
	margin-left: 25px;
}
.no-padding-print{
	padding-left: 90px !important;
}
.dlpro-manufacturer-name-custom{
	font-size: 10px;
    width: 100%;
}
.logoP2BouPromoDemiPromo {
    background-color: #e8182e;
    color: #fff;
    border-radius: 50px;
    font-size: 10px;
    font-weight: bold;
    width: 70%;
    text-align: center;
    margin: 0 auto;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.EtiquetteDemiSize{
	width: 5.7cm;
  height: 4cm;
}

.logoFabricantEtiquetteDemi {
         position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
}
.logoFabricantEtiquetteDemi img {
    height: 20px;
}

.etiquetteDemiCheckGreen {
       position: absolute;
    top: 5px;
    left: 5px;
}
.etiquetteDemiCheckGreen img{
    width: 20px;
} 

.conteneurEtiquetteDemi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 0.1px;
  position: relative;
  width: 5.7cm;
  height: 4cm;
}

/* éléments contenus dans l'étiquette par ordre d'apparition de haut en bas */
.logoFabricantDemi {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoFabricantDemi img {
  height: 30px;
  margin-top: 2px;
}

.logoFabricantDemi img + img {
  height: 20px;
    position: absolute;
    right: 0;
    top: 0;
}

.PVCDemi {
    color: black;
    margin-bottom: 10px;
    font-size: 10px;
    position: absolute;
    bottom: 37%;
    left: 0;
	
}

.logoP2BouPromoDemiPrixDebad{
	       display: flex;
    justify-content: end;
    align-items: end;
}
.logoP2BouPromoDemiPrixDebad span {
    font-size: 11px;
    margin-right: 2px;
    line-height: 13px;
}
.logoP2BouPromoDemiPrixDebad img{
	        width: 25%;
}

.PVCDemiPrice {
       color: black;
    font-size: 13px;
    position: absolute;
    bottom: 2px;
    left: 0;
    text-align: center;
    width: 100%;
}

.bigSizeDemiEtiquette {
  color: red;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 35px;    line-height: 11px;
	    width: 90%;
}

.mediumSizeDemiEtiquette {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin: 2px 0 2px 0;
}

.smallSizeDemiEtiquette {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7px;
  font-weight: 600;
  margin: 2px 0 2px 0;
}

.logoP2BouPromoDemi {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoP2BouPromoDemi img {
  height: 20px;
}

.PVDemiIMG{
	  
		     display: block;    margin: 0 auto;
}

.PVDemi {
    position: absolute;
    color: var(--blue-color);
    font-size: 18px;
    font-weight: 600;
    bottom: 11%;
    right: 0;
    text-align: right;
}

.PVDemiPrice{
	     position: absolute;
    top: 38%;
    right: 10px;
    text-align: right;
    color: white;
    font-size: 18px;
}

.caracteristiquesProduitDemi {
  color: black;
    font-size: 10px;
    font-weight: 600;
    position: absolute;
    bottom: 1px;
}

@media print {
	.no-print{
		display: none !important;
	}
  table { page-break-after:auto }
  table tr    { page-break-inside:avoid; page-break-after:auto }
  table thead { display:table-header-group }
  table tfoot { display:table-footer-group }
  .page-break  { display: block; page-break-before: always; }
  .no-padding-print{ padding-left: 0 !important;}
  .no-margin-print{margin-top: 0;}
  .no-margin-top-left{ margin-top: 0; margin-left: 0;}
 }
