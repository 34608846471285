/* conteneur englobant l'ensemble des informations */
table{
    border-collapse:collapse;
}
table,
tr,
td,
div {
    page-break-inside: avoid;
}
.EtiquetteRaquettes{
  width: 11.5cm;
  height: 4cm;
  border: solid 0.1px;
}
.EtiquetteRaquettesSize{
	width: 11.5cm;
	height: 4cm;
}
.dlpro-manufacturer-name-custom{
	font-size: 10px;
    width: 100%;
}
.logoP2BouPromoRaquettesPromo {
        background-color: #e8182e;
    color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 25px;
    font-size: 10px;
    font-weight: bold;
}
.logoP2BouPromoRaquettesPrixDebad{
	   display: flex;
    justify-content: center;
      align-items: end;
}
.logoP2BouPromoRaquettesPrixDebad span {
    font-size: 13px;
    margin-right: 2px;
    line-height: 15px;
}
.logoP2BouPromoRaquettesPrixDebad img {
    width: 40%;
}
.no-margin-print{margin-top: 20px;}
.no-margin-top-left{
	margin-top: 20px;
	margin-left: 25px;
}
.conteneurEtiquetteRaquettes {
	display: block;
	width: 11.5cm;
	height: 4cm;
}
.no-padding-print{
	padding-left: 90px !important;
}
.conteneurCaracteristiquesRaquettes {
	width: 65%;
    float: left;
	position: relative;
	height: 100%;
}
.raquettesCheckGreen {
    position: absolute;
    top: 5px;
    left: 5px;
}
.raquettesCheckGreen img{
    width: 20px;
}  
.raquettesBlockTop{
	position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
	    margin-top: -10px;
}
.raquettesBlockTop img{
	width: 110px;
}
.raquettesBlockCenter{
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    margin: 10px 0;
}
.bigSizeEtiquetteRaquettes {
     font-size: 18px;
    font-weight: 600;
    width: 90%;
    margin: 0 auto;
    color: red;
    text-align: center;
}
.conteneurPrixRaquettes {
	width: 35%;
	float: left;
	height: 100%;
	overflow: hidden;
	    position: relative;
}
.PVCRaquettes {
     color: #000;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-decoration: line-through;
    background: #ffff;
    position: relative;
	    margin-top: 10px;
		 margin-right: 2px;
}
.PVCRaquettes img{
	   height: 40px;
}
.PVCRaquettesPrice{
	    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
    width: 100%;
	    font-size: 13px;
}
.PVCRaquettesPrice span{
	text-decoration: line-through;
}
/* conteneur représentant la partie gauche de l'étiquette (logo, ref, caractéristiques) */
.smallSizeEtiquetteRaquettes {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.caracteristiquesProduitRaquettes {
        color: black;
    font-weight: 600;
    font-size: 10px;
    position: absolute;
    bottom: 5px;
    text-align: center;
    width: 100%;
}

/* conteneur représentant la partie droite de l'étiquette (PVC, logo P2B ou Promo, PV) */


.logoP2BouPromoRaquettes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	      margin: 20px 0 5px;
}

.PVRaquettes img {
  height: 40px;
}

.PVRaquettes {
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 600;
	    position: relative;
		 margin-right: 2px;
}
.PVRaquettesPrice{
	    position: absolute;
    top: 2px;
    right: 10px;
    text-align: right;
    width: 100%;
}
@media print {
	.no-print{
		display: none !important;
	}
  table { page-break-after:auto }
  table tr    { page-break-inside:avoid; page-break-after:auto }
  table thead { display:table-header-group }
  table tfoot { display:table-footer-group }
  .page-break  { display: block; page-break-before: always; }
  .no-padding-print{ padding-left: 0 !important;}
  .no-margin-print{margin-top: 0;}
  .no-margin-top-left{ margin-top: 0; margin-left: 0;}
 }
