/* conteneur englobant l'ensemble des informations */
table{
    border-collapse:collapse;
}
tr>td {
  padding: 5px;
}
table,
tr,
td,
div {
    page-break-inside: avoid;
}
.EtiquetteChaussures{
	width: 25cm;
  height: 3.5cm;
  border: solid 0.1px;

}
.logoP2BouPromoChaussuresPromo {
           background-color: #e8182e;
    color: #fff;
    border-radius: 50px;
    font-size: 11px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 25px;
}
.dlpro-manufacturer-name-custom{
	font-size: 10px;
    width: 100%;
}
.logoP2BouPromoChaussuresPrixDebad{
	   display: flex;
    justify-content: center;
      align-items: end;
}
.logoP2BouPromoChaussuresPrixDebad span {
    font-size: 14px;
    margin-right: 2px;
    line-height: 16px;
}
.logoP2BouPromoChaussuresPrixDebad img {
    width: 25%;
}
.PVCChaussuresPriceValue{
	    text-decoration: line-through;
}
.EtiquetteChaussuresSize{
	width: 25cm;
  height: 3.5cm;
}
.no-margin-print{margin-top: 20px;}
.no-margin-top-left{
	margin-top: 20px;
	margin-left: 25px;
}
.no-padding-print{
	padding-left: 90px !important;
}
.chaussuresCheckGreen {
       position: absolute;
    top: 5px;
    left: 5px;
}
.chaussuresCheckGreen img{
    width: 20px;
} 
.logoFabricantChaussures {
         position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
}
.logoFabricantChaussures img {
    height: 30px;
}
.bigSizeEtiquetteChaussures {
       color: red;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    line-height: 28px;
}
.etiquetteDailyMailing .EtiquetteChaussures{
	    
}
.conteneurEtiquetteChaussures {
  display: block;
  width: 25cm;
  height: 3.5cm;
}

/* conteneur représentant la partie gauche de l'étiquette (logo, ref, caractéristiques) */
.conteneurCaracteristiquesChaussures {
  width: 75%;
  float: left;
  position: relative;
  height: 100%;
}





.logoFabricantChaussures img + img {
  height: 30px;
}



.smallSizeEtiquetteChaussures {
  height: 63.12px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.caracteristiquesProduitChaussures {
      color: black;
    font-weight: 600;
    position: absolute;
    bottom: 5px;
    text-align: center;
    width: 100%;
}

/* conteneur représentant la partie droite de l'étiquette (PVC, logo P2B ou Promo, PV) */

.conteneurPrixChaussures {
	width: 25%;
	float: left;
	height: 100%;
	overflow: hidden;
	    position: relative;
}

.PVCChaussures {
     color: #000;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-decoration: line-through;
    background: #ffff;
    position: relative;
	    margin-top: 10px;
		    margin-right: 2px;
}

.PVCChaussuresPrice{
	     position: absolute;
    top: 5px;
    right: 0;
    text-align: center;
    width: 100%;
    font-size: 20px;
}

.PVCChaussuresPriceName{
	margin-right: 10px;
}

.logoP2BouPromoChaussures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
         margin: 6px 0 3px;
}

.PVChaussures {
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 600;
	    position: relative;
		 margin-right: 2px;
}
.PVChaussuresPrice{
	     position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: 100%;
    font-size: 28px;
}
@media print {
	.no-print{
		display: none !important;
	}
  table { page-break-after:auto }
  table tr    { page-break-inside:avoid; page-break-after:auto }
  table thead { display:table-header-group }
  table tfoot { display:table-footer-group }
  .page-break  { display: block; page-break-before: always; }
  .no-padding-print{ padding-left: 0 !important}
  .no-margin-print{margin-top: 0;}
  .no-margin-top-left{ margin-top: 0; margin-left: 0;}
 }
